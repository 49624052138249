import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { Observable } from "rxjs";
import { Farm } from "src/app/models/farm.model";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-farmer-home",
  templateUrl: "./farmer-home.component.html",
  styleUrls: ["./farmer-home.component.scss"],
})
export class FarmerHomeComponent implements OnInit {
  farm$: Observable<Farm>;
  applicants$: Observable<User[]>;

  profileEdited = false;
  checklist = [];

  faArrowRight = faArrowRight;

  constructor(private api: ApiService, private auth: AuthService) {
    this.auth.user$.subscribe((data) => {
      this.profileEdited = data.profileEdited;
      this.checklist = [
        {
          state: this.profileEdited,
          text: "Lege dein Profil an",
        },
        {
          state: data.foundOtherParty,
          text: "Finde Pluckies in deiner Nähe",
        },
        {
          state: data.engagedOtherParty,
          text: "Schreibe einem Plucky im Chat",
        },
        {
          state: data.informedAboutInitiative,
          text: "Informiere dich über unsere Initiative",
        },
      ];
    });
  }

  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      if (user && user.uid) {
        this.api.getFarmByUser(user.uid).subscribe((farm) => {
          if (farm && farm.length > 0) {
            this.applicants$ = this.api.getApplicantsForFarm(farm[0]);
          }
        });
      }
    });
  }

  getGalleryItems(applicants: User[]) {
    return applicants.map((a) => ({
      name: a.displayName,
      image: a.photoUrl,
      url: `/employee-detail/${a.uid}`,
    }));
  }
}
