export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDqAg-417M5QDXwUnkzDl29qoYZoCCpaHo",
    authDomain: "plucky-backend.firebaseapp.com",
    databaseURL: "https://plucky-backend.firebaseio.com",
    projectId: "plucky-backend",
    storageBucket: "plucky-backend.appspot.com",
    messagingSenderId: "86359356199",
    appId: "1:86359356199:web:46d4df7e72756f09542f02"
  },
  googleMaps: {
    apiKey: "AIzaSyC9uC0iF7HgUf5JUSl5iMkqMM1NbH2xVTc",
    libraries: ["places"]
  },
  smtp: {
    host: "smtp.strato.de",
    email: "notification@myplucky.eu",
    pass: ""
  },
  reCaptcha: {
    apiKey: "6LflveQUAAAAACtPNlDcwsfLKgclN4TCJJPSWLTT"
  }
};
