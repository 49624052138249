import { Component, OnInit } from "@angular/core";
import {
  faWineGlassAlt,
  faLemon,
  faTree,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { FarmTag, Farm } from "src/app/models/farm.model";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-farmer-profile",
  templateUrl: "./farmer-profile.component.html",
  styleUrls: ["./farmer-profile.component.scss"],
})
export class FarmerProfileComponent implements OnInit {
  public editState = false;
  editFarm: Farm;
  resetFarm: Farm;
  editOwner: User;
  resetOwner: User;
  error = "";
  isNewFarm;

  public farmTags: FarmTag[] = [
    { icon: faLemon.iconName.toString(), name: "Obstbau", id: "obstbau" },
    { icon: faTree.iconName.toString(), name: "Gartenbau", id: "gartenbau" },
    { icon: faLeaf.iconName.toString(), name: "Biohof", id: "biohof" },
    {
      icon: faWineGlassAlt.iconName.toString(),
      name: "Weinbau",
      id: "weinbau",
    },
  ];

  farms$: Observable<Farm[]>;
  constructor(public auth: AuthService, private api: ApiService) {}
  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      if (user && user.uid) {
        this.farms$ = this.api.getFarmByUser(this.auth.user?.uid);
        // In this component Farmer Profile the authenticated user is also the owner of the farm
        this.editOwner = Object.assign({}, user);
        this.resetOwner = Object.assign({}, user);
        this.farms$.subscribe((data) => {
          if (data && data.length > 0) {
            this.editFarm = data[0];
            this.resetFarm = Object.assign({}, data[0]);
            this.isNewFarm = false;
          } else if (data && data.length === 0) {
            // create a new farm
            // add the current uid to the new farm members
            const farm: Farm = {
              applicants: [],
              confirmedApplicants: [],
              minimumDuration: 0,
              hoursPerDay: 0,
              needsDriversLicense: false,
              needsCar: false,
              numberOfRequiredEmployees: 0,
              hasHousing: false,
              housingDetails: "",
              difficultyLevel: 1,
              salary: 0,
              name: "",
              farmTags: [],
              member: [user.uid],
              productTags: [],
              image: "https://i.pravatar.cc/300",
              tasks: [],
              months: [],
              location: null,
              id: null,
              production: [
                {
                  name: "Äpfel",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Birnen",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Kirschen",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Pflaumen",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Quitten",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Pfirsiche",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf Bäumen",
                },
                {
                  name: "Himbeeren",
                  checked: false,
                  category: "Obst",
                  orientation: "An Sträuchern",
                },
                {
                  name: "Trauben",
                  checked: false,
                  category: "Obst",
                  orientation: "An Sträuchern",
                },
                {
                  name: "Stachelbeeren",
                  checked: false,
                  category: "Obst",
                  orientation: "An Sträuchern",
                },
                {
                  name: "Johannisbeeren",
                  checked: false,
                  category: "Obst",
                  orientation: "An Sträuchern",
                },
                {
                  name: "Erdbeeren",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Blaubeeren",
                  checked: false,
                  category: "Obst",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Bohnen",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Paprika",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Champignon",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Tomaten",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Gurken",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Kohl",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Salat",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Zwiebeln",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Pilze",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Spinat",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Kraut",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Möhren",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Mais",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Auf dem Feld",
                },
                {
                  name: "Kartoffeln",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Unter der Erde",
                },
                {
                  name: "Sellerie",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Unter der Erde",
                },
                {
                  name: "Spargel",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Unter der Erde",
                },
                {
                  name: "Radieschen",
                  checked: false,
                  category: "Gemüse",
                  orientation: "Unter der Erde",
                },
                {
                  name: "Getreide",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Sonnenblumen",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Raps",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Zuckerrüben",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Weinbau",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Zierpflanzen",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Heilkräuter",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Baumschule",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Verkauf",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Hopfenbau",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Aussaat",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
                {
                  name: "Verpacken",
                  checked: false,
                  category: "Sonstiges",
                  orientation: null,
                },
              ],
              address: "",
              city: "",
              postalCode: "",
              country: "",
            };
            this.editFarm = farm;
            this.resetFarm = Object.assign({}, farm);
            this.isNewFarm = true;
            this.editState = true;
          }
        });
      }
    });
  }
  reset() {
    // deep copy
    this.editFarm = Object.assign({}, this.resetFarm);
    this.editOwner = Object.assign({}, this.resetOwner);
    this.error = "";
    this.setEditMode(false);
  }

  async update() {
    this.error = "";
    if (!this.editFarm.name) {
      // error
      this.error = "Der Name darf nicht leer sein";
      return;
    }
    if (!this.editFarm.address) {
      this.error = "Die Straße und Hausnummer muss definiert werden";
      return;
    }
    if (!this.editFarm.city) {
      this.error = "Der Ort muss definiert werden";
      return;
    }
    if (!this.editFarm.postalCode) {
      this.error = "Die Postleitzahl muss definiert werden";
      return;
    }
    if (!this.editFarm.country) {
      this.error = "Das Land muss definiert werden";
      return;
    }
    try {
      if (this.isNewFarm) {
        await this.api.addFarm(this.editFarm);
      } else {
        await this.api.updateFarm(this.editFarm.id, this.editFarm);
      }
      this.editOwner.profileEdited = true;
      await this.api.updateProfile(this.editOwner);
    } catch (error) {
      console.log(error);
    }
    this.setEditMode(false);
  }

  setEditMode(edit: boolean) {
    this.editState = edit;
  }
}
