import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Observable, Subject } from "rxjs";
import { Farm } from "src/app/models/farm.model";
import { Router } from "@angular/router";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-employee-home",
  templateUrl: "./employee-home.component.html",
  styleUrls: ["./employee-home.component.scss"],
})
export class EmployeeHomeComponent implements OnInit {
  showFarms = false;
  iconArrowRight = faArrowRight;
  farms$: Observable<Farm[]>;
  farms: Farm[];
  displayFarms: Farm[];
  selectedFarm: Farm;

  profileEdited = false;
  checklist = [];

  constructor(
    private router: Router,
    private auth: AuthService,
    private api: ApiService
  ) {
    this.farms$ = this.api.getFarms();
    this.farms$.subscribe((farms) => {
      this.farms = farms;
      if (farms.length > 3) this.displayFarms = farms.slice(0, 3);
      else this.displayFarms = farms;
    });
    this.auth.user$.subscribe((data) => {
      this.profileEdited = data.profileEdited;
      this.checklist = [
        {
          state: this.profileEdited,
          text: "Lege dein Profil an",
        },
        {
          state: data.foundOtherParty,
          text: "Finde Farmen in deiner Nähe",
        },
        {
          state: data.engagedOtherParty,
          text: "Bewirb dich auf eine Farm",
        },
        {
          state: data.informedAboutInitiative,
          text: "Informiere dich über unsere Initiative",
        },
      ];
    });
  }

  ngOnInit(): void {}

  goToProfile() {
    this.router.navigate(["/employee-profile"]);
  }

  showFarmDetails(farm: Farm) {
    this.selectedFarm = farm;
    this.showFarmsModal();
  }

  showFarmsModal() {
    this.showFarms = true;
  }

  hideFarmsModal() {
    this.showFarms = false;
  }
}
