<div class="modal">
  <div class="title-row">
    <mat-card-title>Farmübersicht</mat-card-title>
    <fa-icon
      *ngIf="selectedFarm == null"
      [icon]="closeIcon"
      (click)="hideModal.emit()"
    ></fa-icon>
    <fa-icon
      *ngIf="selectedFarm != null"
      [icon]="backIcon"
      (click)="goToList()"
    ></fa-icon>
  </div>
  <div *ngIf="selectedFarm == null">
    <mat-divider></mat-divider>
    <ng-container>
      <div class="grid-view">
        <app-farm
          *ngFor="let farm of farms"
          [farm]="farm"
          showApplyButton="true"
          (clickEvent)="goToDetail(farm)"
        ></app-farm>
      </div>
    </ng-container>
  </div>
  <div *ngIf="selectedFarm != null">
    <div class="edit-profile-button">
      <button mat-button *ngIf="!applied()" (click)="apply()">
        Jetzt Bewerben?
        <fa-icon style="font-size: 18px;" [icon]="backIcon"></fa-icon>
      </button>
      <button color="warn" mat-button *ngIf="applied()" (click)="deregister()">
        Bewerbung Zurückziehen
      </button>
    </div>
    <app-profile-farm
      [showContact]="true"
      [farm]="selectedFarm"
      [owner]="owner"
    ></app-profile-farm>
  </div>
  <ng-template #loading>
    Projekte laden...
  </ng-template>
</div>
