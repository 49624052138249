import { Component, OnInit, Input } from "@angular/core";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/models/user.model";
import { Observable } from "rxjs";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-employee-detail",
  templateUrl: "./employee-detail.component.html",
  styleUrls: ["./employee-detail.component.scss"],
})
export class EmployeeDetailComponent implements OnInit {
  locationIcon = faMapMarkerAlt;
  phoneIcon = faPhone;
  mailIcon = faEnvelope;
  user$: Observable<User>;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private api: ApiService
  ) {
    this.auth.user$.subscribe((user) => {
      if (user.type == "farmer") this.updateFarmerFoundOtherParty(user);
    });
  }

  async updateFarmerFoundOtherParty(user: User) {
    user.foundOtherParty = true;
    await this.api.updateProfile(user).catch((err) => {
      console.log(err);
    });
  }

  ngOnInit(): void {
    let uid = this.activeRoute.snapshot.paramMap.get("uid");
    this.user$ = this.api.getProfile(uid);
  }
}
