<div class="container">
  <div *ngIf="showTopBox" class="topBoxContainer appWidth">
    <div class="topBoxPrimary"></div>
    <div class="topBoxSecond"></div>
  </div>
  <div class="appWidth containerBackground">
    <div class="padding">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="showBotBox" class="bottomBoxContainer appWidth">
    <div class="bottomBoxPrimary"></div>
    <div class="bottomBoxSecond"></div>
  </div>
</div>

<app-bottom-nav *ngIf="showNav"></app-bottom-nav>
