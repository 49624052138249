import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  NgModule
} from "@angular/core";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Farm } from "src/app/models/farm.model";
import { FormControl } from '@angular/forms';
import {AgmCoreModule, MapsAPILoader} from '@agm/core';
import * as firebase from 'firebase';
import {ApiService} from '../../services/api.service';

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"]
})
export class MapComponent implements OnInit {
  zoom: Number = 18;
  @Input() editState = false;
  @Input() farm: Farm;
  faMapMarkerAlt = faMapMarkerAlt;

  public searchControl: FormControl;
  public address: FormControl;
  public city: FormControl;
  public postalCode: FormControl;
  public country: FormControl;

  private geoCoder;
  private editFarm;

  constructor(private api: ApiService, private mapsAPILoader: MapsAPILoader) {}


  ngOnInit(): void {
    this.address = new FormControl();
    this.city = new FormControl();
    this.postalCode = new FormControl();
    this.country = new FormControl();
    this.onLocationChanged();
  }

  async onLocationChanged() {
    if (this.farm.address && this.farm.city && this.farm.postalCode && this.farm.country)  {
      this.editFarm = this.farm;
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        this.updateLocation(this.editFarm);
      });
    }
  }

  private updateLocation(editFarm: Farm) {
    console.log('Update Location');
    const address = editFarm.address + ', ' + editFarm.postalCode + ' ' + editFarm.city + ', ' + editFarm.country;
    console.log(address);
    this.geoCoder.geocode(
      {'address': address},
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.editFarm.location.latitude = results[0].geometry.location.lat();
            this.editFarm.location.longitude = results[0].geometry.location.lng();
            this.update();
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
  }

  private async update()  {
    await this.api.updateFarm(this.editFarm.id, this.editFarm);
  }
}
