<div class="container">
  <h1>Hallo {{ user.displayName }}</h1>
  <h2>Bewerbung</h2>
  <ng-container *ngIf="chat$ | async as chat">
    <div class="chat-container">
      <div class="chat-header">
        <div
          *ngIf="chat[0].partner.photoUrl"
          class="avatar"
          [style.background-image]="'url(' + chat[0].partner.photoUrl + ')'"
        ></div>
        <div *ngIf="!chat[0].partner.photoUrl" class="avatar-placeholder"></div>
        <div class="chat-header-name">{{ chat[0].partner.displayName }}</div>
      </div>
      <app-chat-bubble
        *ngFor="let message of chat[0]?.messages"
        [message]="message.message"
        [sender]="message.uid === user.uid"
        [time]="message.createdAt"
      ></app-chat-bubble>
    </div>
  </ng-container>
  <mat-form-field class="appWidth">
    <mat-label>Antworten</mat-label>
    <input
      [(ngModel)]="userInput"
      matInput
      autocomplete="off"
      (keydown.enter)="onSendMessage()"
      placeholder="Sende eine Nachricht..."
    />
    <mat-icon matSuffix (click)="onSendMessage()"
      ><fa-icon class="message-input-icon" [icon]="sendIcon"></fa-icon
    ></mat-icon>
  </mat-form-field>
</div>
<app-bottom-nav></app-bottom-nav>
