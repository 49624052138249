import { Component, OnInit } from "@angular/core";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user.model";
import { ApiService } from "src/app/services/api.service";
import { finalize } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-employee-profile",
  templateUrl: "./employee-profile.component.html",
  styleUrls: ["./employee-profile.component.scss"],
})
export class EmployeeProfileComponent implements OnInit {
  editUser: User;
  editMode = false;
  faCamera = faCamera;

  nameControl: FormControl;
  phoneControl: FormControl;
  cityControl: FormControl;

  constructor(public auth: AuthService, private api: ApiService) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((data) => {
      if (data) {
        this.editUser = data;
        this.nameControl = new FormControl(this.editUser.displayName, [
          Validators.required,
        ]);
        this.phoneControl = new FormControl(this.editUser.phone, []);
        this.cityControl = new FormControl(this.editUser.city, []);
      }
    });
  }

  discard() {
    const user = this.auth.user;
    if (user) {
      // deep copy
      this.editUser = Object.assign({}, user);
    }
    console.log(user);
    this.toggleEditMode();
  }

  async update() {
    this.editUser.displayName = this.nameControl.value;
    this.editUser.phone = this.phoneControl.value;
    this.editUser.city = this.cityControl.value;
    this.editUser.profileEdited = true;
    await this.api.updateProfile(this.editUser).catch((err) => {
      console.log(err);
      this.setEditMode(false);
    });
    this.setEditMode(false);
  }

  setEditMode(edit: boolean) {
    this.editMode = edit;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  async onImageChanged(event) {
    try {
      const file = event.target.files[0];
      const url = await this.api.uploadImage(file);
      this.editUser.photoUrl = url;
    } catch (error) {
      console.log(error);
    }
  }
}
