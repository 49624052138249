<div class="container">
  <h1>Chats</h1>

  <ng-container *ngIf="chat$ | async as chats; else loading">
    <div
      class="chat-list-container"
      *ngFor="let chat of chats"
      (click)="goToDetail(chat)"
    >
      <mat-card class="chat-item">
        <div class="chatHeader">
          <div class="profileData">
            <div
              *ngIf="chat.partner.photoUrl"
              class="avatar"
              [style.background-image]="'url(' + chat.partner.photoUrl + ')'"
            ></div>
            <div *ngIf="!chat.partner.photoUrl" class="avatar-placeholder"></div>
            <h3 class="chat-name">{{ chat.partner.displayName }}</h3>
          </div>
          <div class="chatAction">
            <button mat-raised-button color="primary" (click)="goToDetail(chat)">
              Öffne Chat
            </button>
          </div>
        </div>
        <div class="chatFooter" *ngIf="chat.messages[0]">
          <div class="chatMessage">
            {{ chat.messages.reverse().sort()[0].message }}
          </div>
          <div class="chatDate">
            {{ chat.messages.sort()[0].createdAt.toDate() | date }}
          </div>
        </div>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="!chat$">
    <h3>Hier werden deine Chats aufgelistet</h3>
  </ng-container>
  <ng-template #loading>
    Chats laden...
  </ng-template>
</div>
