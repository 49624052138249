<div class="flex-row" *ngFor="let row of table">
  <div class="flex-col" *ngFor="let col of row" (click)="toggleActive(col)">
    <sup *ngIf="!col.active" class="month-pill-button state-disabled">
      {{ col.text }}
    </sup>
    <sup *ngIf="col.active" class="month-pill-button state-active">
      {{ col.text }}
    </sup>
  </div>
</div>
<div class="info-container" *ngIf="activeInfoText && inactiveInfoText">
  <fa-icon class="info-icon" [icon]="infoIcon"></fa-icon>
  <div class="info-card">
    <div class="chip-container">
      <div class="active-info-chip"></div>
      <div class="chip-text">{{ activeInfoText }}</div>
    </div>
    <div class="chip-container">
      <div class="inactive-info-chip"></div>
      <div class="chip-text">{{ inactiveInfoText }}</div>
    </div>
  </div>
</div>
