<div class="gallery-container">
  <h3 class="gallery-title">{{ title }}</h3>
  <div class="gallery-list">
    <div
      class="gallery-element"
      *ngFor="let item of galleryItems"
      (click)="goToItem(item)"
    >
      <div
        *ngIf="item.image"
        class="avatar"
        [style.background-image]="'url(' + item.image + ')'"
      ></div>
      <div *ngIf="!item.image" class="avatar-placeholder"></div>
      <div class="gallery-text">{{ item.name }}</div>
    </div>
  </div>
  <div class="right-link">
    <a class="link" [routerLink]="findMoreRoute"
      >Weitere finden <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon
    ></a>
  </div>
</div>
