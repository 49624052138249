<mat-card class="card-wrapper">
  <div class="logo"></div>
  <ng-container *ngIf="registerActive">
    <ng-container *ngTemplateOutlet="registerFormTemplate"></ng-container>
  </ng-container>
  <ng-container *ngIf="!registerActive">
    <ng-container *ngTemplateOutlet="loginFormTemplate"></ng-container>
  </ng-container>
</mat-card>

<ng-template #loginFormTemplate>
  <form
    [formGroup]="loginForm"
    class="login-form-container"
    (ngSubmit)="login()"
  >
    <h2>Einloggen</h2>
    <h3>{{ userType === "plucky" ? "Plucky" : "Farmer" }}</h3>

    <mat-form-field appearance="outline">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        formControlName="emailControl"
        placeholder="Maxmustermann@email.com"
        (keydown.enter)="login()"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          loginEmailControl().invalid &&
          (loginEmailControl().dirty || loginEmailControl().touched)
        "
      >
        Bitte gib eine korrekte Email-Adresse an
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort</mat-label>
      <input
        matInput
        formControlName="passwordControl"
        (keydown.enter)="login()"
        placeholder="**********"
        type="password"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          loginPasswordControl().invalid &&
          (loginPasswordControl().dirty || loginPasswordControl().touched)
        "
      >
        Bitte gib ein Passwort an
      </mat-hint>
    </mat-form-field>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      class="login-button"
      [disabled]="!loginForm.valid"
    >
      Einloggen
    </button>
    <mat-spinner [diameter]="25" *ngIf="loading"></mat-spinner>
    <p class="error" *ngIf="error">Überprüfe deine Eingaben</p>
    <button mat-button (click)="toggleLogin()">
      Stattdessen Registrieren?
    </button>
  </form>
</ng-template>

<ng-template #registerFormTemplate>
  <form
    class="login-form-container"
    [formGroup]="registerForm"
    (ngSubmit)="register()"
  >
    <h2>Registrieren</h2>
    <h3>{{ userType === "plucky" ? "Plucky" : "Farmer" }}</h3>
    <mat-form-field appearance="outline">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        formControlName="emailControl"
        (keydown.enter)="register()"
        placeholder="Maxmustermann@email.com"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          registerEmailControl().invalid &&
          (registerEmailControl().dirty || registerEmailControl().touched)
        "
      >
        Bitte gib eine korrekte Email-Adresse an
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="nameControl"
        placeholder="Max Mustermann"
        (keydown.enter)="register()"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          registerNameControl().invalid &&
          (registerNameControl().dirty || registerNameControl().touched)
        "
      >
        Bitte gib einen Namen an
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort</mat-label>
      <input
        matInput
        formControlName="passwordControl"
        (keydown.enter)="register()"
        placeholder="**********"
        type="password"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          registerPasswordControl().invalid &&
          (registerPasswordControl().dirty || registerPasswordControl().touched)
        "
      >
        Bitte gib ein Passwort an
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort wiederholen</mat-label>
      <input
        matInput
        formControlName="confirmPasswordControl"
        (keydown.enter)="register()"
        placeholder="**********"
        type="password"
        required
      />
      <mat-hint
        class="error"
        *ngIf="
          registerForm.errors?.differentPasswords &&
          (registerForm.touched || registerForm.dirty)
        "
      >
        Die Passwörter müssen gleich sein
      </mat-hint>
    </mat-form-field>
    <mat-checkbox formControlName="privacyControl" required>
      Hiermit akzeptiere ich<br />
      die
      <a href="/privacy" target="_blank">Datenschutzerklärung</a>
    </mat-checkbox>
    <mat-hint
      class="error checkbox-hint"
      *ngIf="
        registerPrivacyControl().invalid &&
        (registerPrivacyControl().dirty || registerPrivacyControl().touched)
      "
    >
      Der Datenschutzerklärung muss zugestimmt werden
    </mat-hint>
    <mat-checkbox formControlName="newsletterControl">
      Hiermit akzeptiere ich den <br />Eintrag in den Newsletter.
    </mat-checkbox>
    <re-captcha
      (resolved)="reCaptchaResolved($event)"
      siteKey="{{ reCaptchaApiKey }}"
    ></re-captcha>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      class="register-button"
      [disabled]="!registerForm.valid"
    >
      Registrieren
    </button>
    <mat-spinner [diameter]="25" *ngIf="loading"></mat-spinner>
    <p class="error" *ngIf="error">Überprüfe deine Eingaben</p>
    <button mat-button (click)="toggleLogin()">
      Stattdessen Einloggen?
    </button>
  </form>
</ng-template>
