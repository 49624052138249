<div>
  <!-- <div *ngIf="editState; else elseBlock"> -->
  <div>
    <mat-divider></mat-divider>
    <div class="image-section">
      <div class="image-container">
        <div
          *ngIf="farm?.image"
          class="avatar"
          [style.background-image]="'url(' + farm?.image + ')'"
        ></div>
        <div *ngIf="!farm?.image" class="avatar-placeholder"></div>
        <input
          #imageInput
          type="file"
          accept="image/*"
          hidden
          (change)="onImageChanged($event)"
        />
        <div
          mat-button
          class="edit-profile-image-button"
          (click)="imageInput.click()"
          *ngIf="editState"
        >
          <fa-icon [icon]="faCamera"></fa-icon>
          <div class="edit-profile-image-button-text">Bild ändern</div>
        </div>
      </div>
      <h3 *ngIf="!editState">{{ farm.name }}</h3>
      <mat-form-field appearance="outline" fullwidth *ngIf="editState">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="farm.name"
          [readonly]="!editState"
        />
      </mat-form-field>
    </div>
    <div *ngIf="editState">
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Telefonnummer</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="owner.phone"
          [readonly]="!editState"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Name des Ansprechpartners</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="owner.displayName"
          [readonly]="!editState"
        />
      </mat-form-field>
      <div>Bild des Ansprechpartners</div>
      <div class="image-container-row">
        <div
          *ngIf="owner.photoUrl"
          class="avatar"
          [style.background-image]="'url(' + owner.photoUrl + ')'"
        ></div>
        <div *ngIf="!owner.photoUrl" class="avatar-placeholder"></div>
        <input
          #imageInputOwner
          type="file"
          accept="image/*"
          hidden
          (change)="onImageChangedOwner($event)"
        />
        <div
          mat-button
          class="edit-profile-image-button"
          (click)="imageInputOwner.click()"
        >
          <fa-icon [icon]="faCamera"></fa-icon>
          <div class="edit-profile-image-button-text">Bild bearbeiten</div>
        </div>
      </div>
    </div>
    <div *ngIf="!editState">
      <h3>Ansprechpartner:</h3>
      <div class="owner-data" *ngIf="owner.displayName">
        <img class="avatar" *ngIf="owner.photoUrl" src="{{ owner.photoUrl }}" />
        <img class="avatar-placeholderan" *ngIf="!owner.photoUrl" />
        <div>{{ owner.displayName }}</div>
        <div *ngIf="owner.phone">{{ owner.phone }}</div>
      </div>
    </div>
    <h3 class="section-title">Hier befindet sich der Betrieb:</h3>
    <app-map
      [editState]="editState"
      [farm]="farm"
    ></app-map>
    <div class="month-section">
      <h3 class="section-title">Erntehelfer gesucht für folgende Monate:</h3>
      <app-month-overview
        [editable]="editState"
        [months]="farm.months"
        activeInfoText="Wir brauchen Erntehelfer."
        inactiveInfoText="Wir brauchen keine Erntehelfer."
      ></app-month-overview>
    </div>

    <div class="extra-info-container" *ngIf="editState">
      <h3 class="section-title">Zusätzliche Informationen</h3>
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Mindestdauer für die Erntehilfe</mat-label>
        <input
          matInput
          [(ngModel)]="farm.minimumDuration"
          type="text"
          placeholder="in Monaten"
          [readonly]="!editState"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Arbeitsstunden pro Tag</mat-label>
        <input
          matInput
          [(ngModel)]="farm.hoursPerDay"
          type="text"
          placeholder="in Stunden"
          [readonly]="!editState"
        />
      </mat-form-field>
      <label>Level der körperlichen Beanspruchung:</label>
      <mat-slider
        thumbLabel
        [(ngModel)]="farm.difficultyLevel"
        tickInterval="1"
        min="1"
        max="4"
        [disabled]="!editState"
      ></mat-slider>
      <div class="difficulty-description">
        {{ difficultyDescriptions[farm.difficultyLevel - 1] }}
      </div>
      <div class="travel-section">
        <label>Wir bevorzugen Erntehelfer...</label>
        <mat-checkbox
          [disabled]="!editState"
          [(ngModel)]="farm.needsDriversLicense"
        >
          <div class="checkbox-text">
            <div>...mit Führerschein.</div>
            <fa-icon [icon]="faAddressCard"></fa-icon>
          </div>
        </mat-checkbox>
        <mat-checkbox [disabled]="!editState" [(ngModel)]="farm.needsCar">
          <div class="checkbox-text">
            <div>...mit einem eigenem Fahrzeug.</div>
            <fa-icon [icon]="faCarSide"></fa-icon>
          </div>
        </mat-checkbox>
      </div>
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Anzahl der benötigten Erntehelfer</mat-label>
        <input
          matInput
          [(ngModel)]="farm.numberOfRequiredEmployees"
          type="text"
          [readonly]="!editState"
        />
      </mat-form-field>
      <mat-checkbox [disabled]="!editState" [(ngModel)]="farm.hasHousing">
        Wir stellen eine Unterkunft
      </mat-checkbox>
      <mat-form-field *ngIf="farm.hasHousing" appearance="outline" fullwidth>
        <mat-label>Über die Unterkunft</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="farm.housingDetails"
          [readonly]="!editState"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" fullwidth>
        <mat-label>Gehalt</mat-label>
        <input
          matInput
          [(ngModel)]="farm.salary"
          type="text"
          [readonly]="!editState"
        />
      </mat-form-field>
    </div>

    <div class="extra-info-container" *ngIf="!editState">
      <h3 class="section-title">Zusätzliche Informationen</h3>
      <div *ngIf="farm.minimumDuration">
        Mindestdauer für die Erntehilfe: {{ farm.minimumDuration }} Monate
      </div>
      <br />
      <div *ngIf="farm.hoursPerDay">
        Arbeitsstunden pro Tag: {{ farm.hoursPerDay }} Stunden
      </div>
      <br />
      <label>Level der körperlichen Beanspruchung:</label>
      <mat-slider
        thumbLabel
        [(ngModel)]="farm.difficultyLevel"
        tickInterval="1"
        min="1"
        max="5"
        [disabled]="!editState"
      ></mat-slider>
      <div class="difficulty-description">
        {{ difficultyDescriptions[farm.difficultyLevel - 1] }}
      </div>
      <div *ngIf="farm.needsDriversLicense">
        Ein Führerschein wird bevorzugt
      </div>
      <div *ngIf="!farm.needsDriversLicense">
        Ein Führerschein wird nicht benötigt
      </div>
      <br />
      <div *ngIf="farm.needsCar">Ein eigenes Fahrzeug wird bevorzugt</div>
      <div *ngIf="!farm.needsCar">Ein eigenes Fahrzeug wird nicht benötigt</div>
      <br />
      <div *ngIf="farm.numberOfRequiredEmployees">
        Anzahl der benötigten Erntehelfer:
        {{ farm.numberOfRequiredEmployees }} Personen
      </div>
      <br />
      <div *ngIf="farm.hasHousing">
        Es wird eine Unterkunft zu Verfügung gestellt
        <div *ngIf="farm.housingDetails">
          <div>Details:</div>
          <p>{{ farm.housingDetails }}</p>
        </div>
      </div>
      <div *ngIf="farm.salary">Gehalt: {{ farm.salary }}€</div>
    </div>

    <div class="production-section">
      <h3>Wo gedeiht was bei uns?</h3>
      <div class="link" (click)="showProduction = !showProduction">
        <div>Optional: Produktionsausrichtung</div>
        <fa-icon [icon]="faCaretRight" *ngIf="!showProduction"></fa-icon>
        <fa-icon [icon]="faCaretDown" *ngIf="showProduction"></fa-icon>
      </div>
      <div class="production-container" *ngIf="showProduction">
        <div
          class="production-category"
          *ngFor="let category of farm.production"
        >
          <div class="category-name">{{ category.name }}:</div>
          <div
            class="production-subcategory"
            *ngFor="let subcategory of category.subcategories"
          >
            <div class="subcategory-name" *ngIf="subcategory != null">
              {{ subcategory.name }}
            </div>
            <div class="chip-list">
              <div
                [ngClass]="{
                  'chip-active': item.checked,
                  'chip-inactive': !item.checked
                }"
                *ngFor="let item of subcategory.items"
                (click)="item.checked = !item.checked"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showContact && owner">
      <mat-divider></mat-divider>
      <div class="contact">
        <div class="contact-name">
          <h3>{{ owner.displayName }}</h3>
          <a mat-stroked-button color="primary" [href]="'mailto:' + owner.email" class="eMailButton">Sende eine eMail</a>
          <br>
          <a mat-stroked-button color="primary" [routerLink]="'/chat/' + owner.uid" class="chatButton">Konversation starten</a>
        </div>
        <div
          *ngIf="owner.photoUrl"
          class="avatar"
          [style.background-image]="'url(' + owner.photoUrl + ')'"
        ></div>
      </div>
    </ng-container>
  </div>
</div>
