<div class="map-container">

  <div class="contactDetailEdit" *ngIf="editState">
    <mat-form-field appearance="outline" fullwidth>
      <input matInput placeholder="Adresse" spellcheck="off" type="text" class="form-control" [formControl]="address" [(ngModel)]="farm.address" [readonly]="!editState">
    </mat-form-field>
    <mat-form-field appearance="outline" fullwidth>
      <input matInput placeholder="Postleitzahl" spellcheck="off" type="text" class="form-control" [formControl]="postalCode" [(ngModel)]="farm.postalCode" [readonly]="!editState">
    </mat-form-field>
    <mat-form-field appearance="outline" fullwidth>
      <input matInput placeholder="Stadt" spellcheck="off" type="text" class="form-control" [formControl]="city" [(ngModel)]="farm.city" [readonly]="!editState">
    </mat-form-field>
    <mat-form-field appearance="outline" fullwidth>
      <input matInput placeholder="Land" spellcheck="off" type="text" class="form-control" [formControl]="country" [(ngModel)]="farm.country" [readonly]="!editState">
    </mat-form-field>
  </div>

  <div class="contactDetail" *ngIf="!editState">
    <mat-grid-list cols="3">
        <mat-grid-tile>
          <div class="agm-contact">
            <fa-icon [icon]="faMapMarkerAlt" class="markerIcon"></fa-icon>
            <ul>
              <li>{{farm.name}}</li>
              <li>{{farm.address}}</li>
              <li>{{farm.postalCode}} {{farm.city}}</li>
              <li>{{farm.country}}</li>
            </ul>
          </div>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <agm-map [latitude]="farm.location?.latitude" [zoom]="zoom" [longitude]="farm.location?.longitude">
            <agm-marker [latitude]="farm.location?.latitude" [longitude]="farm.location?.longitude"></agm-marker>
          </agm-map>
        </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
