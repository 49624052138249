<h1 class="hello-message">Hallo {{ editUser?.displayName }}</h1>
<h2>Dies ist dein Profil!</h2>
<div class="edit-profile-button">
  <button mat-button *ngIf="!editMode" (click)="toggleEditMode()">
    Profil bearbeiten
  </button>
  <button mat-button color="primary" *ngIf="editMode" (click)="update()">
    Speichern
  </button>
  <button mat-button color="warn" *ngIf="editMode" (click)="discard()">
    Verwerfen
  </button>
</div>
<ng-template #loading>
  Profil lädt...
</ng-template>
<div class="flex-col-edit" *ngIf="editUser; else loading">
  <mat-grid-list cols="2">
    <mat-grid-tile>
      <div
        *ngIf="editUser.photoUrl"
        class="avatar"
        [style.background-image]="'url(' + editUser.photoUrl + ')'"
      ></div>
      <input
          #imageInput
          type="file"
          accept="image/*"
          hidden
          (change)="onImageChanged($event)"
      />
      <div
        class="edit-profile-image-button"
        (click)="imageInput.click()"
        *ngIf="editMode"
      >
        <fa-icon [icon]="faCamera"></fa-icon>
        <div>Bild bearbeiten</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <h3 *ngIf="!editMode">{{ editUser.displayName }}</h3>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-label class="slider-label">Deine Kontaktinformationen?</mat-label>
  <mat-form-field appearance="outline" fullwidth *ngIf="editMode">
    <mat-label>Name</mat-label>
    <input
      matInput
      type="text"
      [formControl]="nameControl"
      [readonly]="!editMode"
      required
    />
    <mat-hint
      class="error"
      *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)"
    >
      Der Name darf nicht leer sein
    </mat-hint>
  </mat-form-field>
  <h3 *ngIf="!editMode">{{ editUser.email }}</h3>
  <mat-form-field appearance="outline" fullwidth *ngIf="editMode">
    <mat-label>Email</mat-label>
    <input
      matInput
      type="text"
      [readonly]="true"
      [(ngModel)]="editUser.email"
      required
    />
  </mat-form-field>
  <h3 *ngIf="!editMode">{{ editUser.phone }}</h3>
  <mat-form-field appearance="outline" fullwidth *ngIf="editMode">
    <mat-label>Telefon</mat-label>
    <input
      matInput
      type="text"
      [formControl]="phoneControl"
      [readonly]="!editMode"
    />
  </mat-form-field>
  <h3 *ngIf="!editMode">{{ editUser.city }}</h3>
  <mat-form-field appearance="outline" fullwidth *ngIf="editMode">
    <mat-label>Ort</mat-label>
    <input
      matInput
      type="text"
      [formControl]="cityControl"
      [readonly]="!editMode"
    />
  </mat-form-field>
  <mat-label class="slider-label"
    >Wie weit bist du gewillt zu reisen?</mat-label
  >
  <mat-slider
    thumbLabel
    tickInterval="10"
    min="10"
    max="50"
    [(ngModel)]="editUser.travelRange"
    [disabled]="!editMode"
  ></mat-slider>
  <div class="slider-unit">{{ editUser.travelRange }} kilometer</div>
  <div class="month-overview-title">Wann kannst du helfen?</div>
  <app-month-overview
    [editable]="editMode"
    [months]="editUser.months"
    activeInfoText="In diesen Monaten kann ich helfen."
    inactiveInfoText="In diesen Monaten kann ich nicht helfen."
  ></app-month-overview>
  <button
    class="logout-button"
    mat-button
    color="warn"
    (click)="auth.signOut()"
  >
    Logout
  </button>
</div>
