<div class="privacyComponent">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Datenschutzerklärung für Plucky
      </mat-card-title>
      <mat-card-subtitle>
        gem. DSGvO
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>
        Vielen Dank für Ihren Besuch auf unserer Webseite und das aufgebrachte Interesse dieser Web Applikation, in folgeenden gennant WebApp.
        Der Inhalt dieser WebApp wurde in Folge vom Hackathon #WirVsWirus erstellt.
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-content>
      <p>
        Im Rahmen der Nutzung dieser WebApp werden personenbezogene Daten von Ihnen durch uns als den für die
        Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der
        festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist.
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-content>
      <p>
        Im Folgenden informieren wir Sie darüber, um welche Daten es sich dabei handelt,
        auf welche Weise sie verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen.
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Diese Datenschutzinformation gilt für die Datenverarbeitung im Rahmen der Nutzung unserer WebApp durch den Verantwortlichen:
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li>Patrick Dinkelacker</li>
        <li>Strickerstraße 10</li>
        <li>72622 Nürtingen</li>
        <li>E-Mail: info@myplucky.eu</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Einbindung von Google Analytics
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics,
        einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten
        Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google
        in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>
      </p>
      <p>
        Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten,
        um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes
        und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme
        Nutzungsprofile der Nutzer erstellt werden.
      </p>
      <p>
        Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
        gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
      </p>
      <p>
        Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können
        die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus
        die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung
        dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
      </p>
      <p>
        Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung
        von Google (<a href="https://policies.google.com/technologies/ads" target="_blank">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch
        Google (<a href="https://policies.google.com/technologies/ads" target="_blank">https://adssettings.google.com/authenticated</a>).
        Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Einbindung von externem Kartenmaterial
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Wir binden in unserer App Kartenmaterial über Google Maps ein.
        Damit die Karte angezeigt werden kann, werden Ihre IP-Adresse und Ihr Standort an
        Google Maps übermittelt. Die Einbindung erfolgt auf Grundlage des Art. 6 Abs. 1 S. 1
        lit. f DSGVO. Sie erfolgt, um unsere Webseite nutzerfreundlicher und interessanter
        zu gestalten. Darin ist ein berechtigtes Interesse im Sinne der vorgenannten Vorschrift zu sehen.
      </p>
      <p>
        Weitere Informationen zum Datenschutz im Zusammenhang mit Google Maps finden Sie hier:
        Google Maps: <a href="https://privacy.google.com/intl/de/index.html" target="_blank">https://privacy.google.com/intl/de/index.html</a>
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Auswertung von Nutzungsdaten
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Anonymisierte Nutzungsdaten werden zum sicheren Betrieb und zur Weiterentwicklung der WebApp ausgewertet.
        Diese Daten lassen sich aber nicht mit Ihrer Identität in Verbindung bringen. Wir haben keine Möglichkeiten,
        diese Daten Ihrer Person zuzuordnen und führen diese Daten auch nicht mit anderen Datenquellen zusammen.
      </p>
      <p>
        Im Rahmen der Nutzung der WebApp werden Daten für statistische und Sicherungszwecke in einer Protokolldatei gespeichert.
      </p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Betroffenenrechte
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li>Gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen.</li>
        <li>Gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden verlangen</li>
        <li>Gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen</li>
        <li>Gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist</li>
        <li>Gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben</li>
        <li>Gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die übermittlung an einen anderen Verantwortlichen zu verlangen und</li>
        <li>Gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>
