<h1 class="title">Hallo Plucky</h1>
<div class="feed-container" *ngIf="!showFarms">
  <div class="section">
    <h2>Schön, dass du da bist!</h2>
    <p>
      MyPlucky ist eine Initiative, die während des wirvsvirus Hackathons der
      Deutschen Bundesregierung gestartet wurde.
    </p>
  </div>
  <mat-divider></mat-divider>
  <div class="section" *ngIf="!profileEdited">
    <h3>Profil anlegen</h3>
    <p class="alert">
      Wir haben festgestellt, dass du noch kein Profil hast. Klicke unten in der
      Leiste auf Profil und lege ein Profil an.
    </p>
  </div>
  <div class="section" *ngIf="profileEdited">
    <h3>Profil angelegt</h3>
    <p>Du hast ein Profil angelegt.</p>
    <div class="right-link">
      <div class="link" (click)="goToProfile()">
        <div>Zu deinem Profil</div>
        <fa-icon [icon]="iconArrowRight"></fa-icon>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="section">
    <h3>Farmen</h3>
    <p class="alert" *ngIf="!profileEdited">
      Hier werden dir Farmen angezeigt, bei denen du dich bewerben kannst,
      sobald du ein Profil angelegt hast
    </p>
    <div class="grid-view" *ngIf="profileEdited">
      <app-farm
        *ngFor="let farm of displayFarms"
        [farm]="farm"
        showApplyButton="true"
        (clickEvent)="showFarmDetails($event)"
      ></app-farm>
    </div>
    <div class="right-link" *ngIf="profileEdited">
      <div class="link" (click)="showFarmsModal()">
        <div>Mehr Farmen anzeigen</div>
        <fa-icon [icon]="iconArrowRight"></fa-icon>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-checklist
    title="Nächste Schritte"
    [listItems]="checklist"
  ></app-checklist>
</div>
<app-employee-home-modal
  *ngIf="showFarms"
  [farms]="farms"
  [selectedFarmInput]="selectedFarm"
  (hideModal)="hideFarmsModal()"
></app-employee-home-modal>
