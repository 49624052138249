import { Component, OnInit } from "@angular/core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import { Wiki } from "src/app/models/wiki.model";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-wiki",
  templateUrl: "./wiki.component.html",
  styleUrls: ["./wiki.component.scss"],
})
export class WikiComponent implements OnInit {
  wiki$: Observable<Wiki[]>;
  wikis: Wiki[];
  public chevronUp = faChevronUp;
  public chevronDown = faChevronDown;
  public plucky = false;
  public collapsed = true;

  constructor(private auth: AuthService, private api: ApiService) {
    this.auth.user$.subscribe((user) => {
      if (!user.informedAboutInitiative)
        this.updateInformedAboutInitiative(user);
    });
  }

  async updateInformedAboutInitiative(user: User) {
    user.informedAboutInitiative = true;
    await this.api.updateProfile(user).catch((err) => {
      console.log(err);
    });
  }

  ngOnInit(): void {
    var type = this.auth.user.type;
    this.fetchWiki(type);
    if (type === "plucky") {
      this.plucky = true;
    } else {
      this.plucky = false;
    }
  }

  fetchWiki(type) {
    this.wiki$ = this.api.getWiki(type);
    this.wiki$.subscribe((posts) => {
      this.wikis = posts;
    });
  }
}
