<div *ngIf="plucky; else elseBlock">
  <div class="container">
    <h1>Hallo Plucky</h1>
    <h2>Willkommen beim Wiki</h2>
    <p>Hier kannst du dich über uns und unsere Motivation, aber auch über die Arbeit, die dich erwarten wird informieren. Es gibt
      hier Tipps und Tricks. Viel Freude beim Lesen!</p>
    <mat-card>
      <div class="logo"></div>
      <h3>Über das Team von Plucky</h3>
      <div>
        8 Leute, 48 Stunden: das hat es gebraucht um uns als Team von Plucky im #wirvsvirus Hackathon zusammenzubringen.
        Wir sind ein Team aus kreativen Köpfen, die sich tagtäglich neue Ideen ausdenken und umsetzen, um die
        Ernte 2020 zu sichern. #wirsindPlucky Schreibt uns gern per Mail oder auf Instagram!
      </div>
    </mat-card>
    <div *ngFor="let item of wikis">
      <mat-card>
        <img class="image" [src]="item.image">
        <h3>{{item.title}}</h3>
        <div>{{item.preBody}}</div>
        <div *ngIf="!item.collapseState">{{item.body}}</div>
        <button mat-icon-button *ngIf="item.collapseState" (click)="item.collapseState=false" class="expand">
          <fa-icon [icon]="chevronDown"></fa-icon>
        </button>
        <button mat-icon-button *ngIf="!item.collapseState" (click)="item.collapseState=true" class="close">
          <fa-icon [icon]="chevronUp"></fa-icon>
        </button>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div class="container">
    <h1>Hallo Farmer</h1>
    <h2>Willkommen beim Wiki</h2>
    <p>Hier kannst du dich über uns und unsere Motivation, aber auch über deine möglichkeiten, die besten Pluckys für
      deine Farm zu finden informieren. Es gibt
      hier Tipps und Tricks. Viel Freude beim Lesen!</p>
    <mat-card>
      <div class="logo"></div>
      <h3>Über das Team von Plucky</h3>
      <div>
        8 Leute, 48 Stunden: das hat es gebraucht um uns als Team von Plucky im #wirvsvirus Hackathon zusammenzubringen.
        Wir sind ein Team aus kreativen Köpfen, die sich tagtäglich neue Ideen ausdenken und umsetzen, um die
        Ernte 2020 zu sichern. #wirsindPlucky Schreibt uns gern per Mail oder auf Instagram!
      </div>
    </mat-card>
    <div *ngFor="let item of wikis">
      <mat-card>
        <img class="image" [src]="item.image">
        <h3>{{item.title}}</h3>
        <div>{{item.preBody}}</div>
        <div *ngIf="!item.collapseState">{{item.body}}</div>
        <button mat-icon-button *ngIf="item.collapseState" (click)="item.collapseState=false" class="expand">
          <fa-icon [icon]="chevronDown"></fa-icon>
        </button>
        <button mat-icon-button *ngIf="!item.collapseState" (click)="item.collapseState=true" class="close">
          <fa-icon [icon]="chevronUp"></fa-icon>
        </button>
      </mat-card>
    </div>
  </div>
</ng-template>
