import { Component, OnInit, Input, Output } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user.model";
import { FarmTag, Farm } from "src/app/models/farm.model";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import {
  faCamera,
  faCaretRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  faTimes,
  faCarSide,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import { MatChipInputEvent } from "@angular/material/chips";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-profile-farm",
  templateUrl: "./profile-farm.component.html",
  styleUrls: ["./profile-farm.component.scss"],
})
export class ProfileFarmComponent implements OnInit {
  faCamera = faCamera;
  faTimes = faTimes;
  faCarSide = faCarSide;
  faAddressCard = faAddressCard;
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;

  @Input() editState: boolean;
  @Input() farm: Farm;
  @Input() owner: User;
  @Input() showContact: boolean;

  difficultyDescriptions = [
    "minimale Beanspruchung",
    "ein bisschen anstrengend",
    "sehr anstrengend",
    "übermäßig anstrengend",
  ];

  showProduction = false;
  production = [];
  constructor(private api: ApiService) {}

  ngOnInit(): void {
    let productionCategories = new Set(
      this.farm.production.map((item) => item.category)
    );
    let productionOrientations = new Set(
      this.farm.production.map((item) => item.orientation)
    );
    productionCategories.forEach((category) => {
      let subcategories = [];
      productionOrientations.forEach((orientation) => {
        subcategories.push({
          name: orientation,
          items: this.farm.production
            .filter((item) => item.category == category)
            .filter((item) => item.orientation == orientation)
            .map((item) => {
              return {
                name: item.name,
                checked: item.checked,
              };
            }),
        });
      });
      this.production.push({
        name: category,
        subcategories: subcategories,
      });
    });
  }

  async onImageChanged(event) {
    try {
      const file = event.target.files[0];
      const url = await this.api.uploadImage(file);
      this.farm.image = url;
    } catch (error) {
      console.log(error);
    }
  }

  async onImageChangedOwner(event) {
    try {
      const file = event.target.files[0];
      const url = await this.api.uploadImage(file);
      this.owner.photoUrl = url;
    } catch (error) {
      console.log(error);
    }
  }
}
