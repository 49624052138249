<h1>Farmdetails</h1>
<div class="edit-profile-button">
  <ng-container *ngIf="farm">
    <button mat-button *ngIf="!applied()" (click)="apply()">
      Jetzt Bewerben?
      <fa-icon style="font-size: 18px;" [icon]="faArrowRight"></fa-icon>
    </button>
    <button color="warn" mat-button *ngIf="applied()" (click)="deregister()">
      Bewerbung Zurückziehen
    </button>
  </ng-container>
</div>
<ng-container *ngIf="farm$ | async as farm; else loading">
  <app-profile-farm
    [showContact]="true"
    [owner]="owner"
    [farm]="farm"
  ></app-profile-farm>
</ng-container>
<ng-template #loading>Loading Farm...</ng-template>
