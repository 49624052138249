<div class="list-container">
  <h3 class="list-title">{{ title }}</h3>
  <div *ngFor="let item of listItems" class="list-element">
    <fa-icon [icon]="circleCheck" size="xs" *ngIf="item.state"></fa-icon>
    <fa-icon [icon]="circle" size="xs" *ngIf="!item.state"></fa-icon>
    <span [ngClass]="{ 'unchecked-list-item-text': !item.state }">{{
      item.text
    }}</span>
  </div>
</div>
