<div class="container">
  <h1 class="element">WILLKOMMEN</h1>
  <div class="logo"></div>
  <div class="action-container">
    <div class="sub-action-container">
      <div class="actionPlucky" (click)="login('plucky')">
        <h1 class="landingH1">Plucky</h1>
        <fa-icon [icon]="['fas', icon.carrot]" size="xl" class="icon"></fa-icon>
      </div>
      <button mat-icon-button color="accent" class="infoButton" matTooltip="
      Was ist ein Plucky? Pluckies werden unsere Erntehelfer genannt.
      Mit ihrem Beitrag leisten sie wertvolle Unterstützungsarbeit.
      Interesse? Dann registriere dich einfach als Plucky.">
        <fa-icon [icon]="['fas', icon.infoCircle]" size="xl" class="info-icon"></fa-icon>
      </button>
    </div>
    <div class="sub-action-container">
      <div class="actionFarmer" (click)="login('farmer')">
        <h1 class="landingH1">Farmer</h1>
        <fa-icon [icon]="['fas', icon.tractor]" size="xl" class="icon"></fa-icon>
      </div>
      <button mat-icon-button color="accent" class="infoButton" matTooltip="
      Was ist ein Farmer ? Farmer sind Landwirte, die dafür sorgen jeden Tag unsere
      Supermarktregale mit leckerem Obst und Gemüse zu füllen. Hierfür brauchen sie
      den Support der Erntehelfer/Pluckies. Bust du Landwirt? Dann registriere dich einfach hier">
        <fa-icon [icon]="['fas', icon.infoCircle]" size="xl" class="info-icon"></fa-icon>
      </button>
    </div>
  </div>
  <div class="hackathon_logo"></div>
</div>
