import { Component, OnInit, Input } from "@angular/core";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  passwordsSameValidator: ValidatorFn = (
    control: FormGroup
  ): ValidationErrors | null => {
    const password = control.get("passwordControl").value;
    const confirmPassword = control.get("confirmPasswordControl").value;
    return password != confirmPassword ? { differentPasswords: true } : null;
  };

  loginForm: FormGroup = new FormGroup({
    emailControl: new FormControl("", [Validators.required, Validators.email]),
    passwordControl: new FormControl("", [Validators.required])
  });
  registerForm: FormGroup = new FormGroup(
    {
      emailControl: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      nameControl: new FormControl("", [Validators.required]),
      passwordControl: new FormControl("", [Validators.required]),
      confirmPasswordControl: new FormControl("", [Validators.required]),
      privacyControl: new FormControl(false, [Validators.requiredTrue]),
      newsletterControl: new FormControl(false, [])
    },
    { validators: this.passwordsSameValidator }
  );

  userType: "farmer" | "plucky";
  thumbsUp = faThumbsUp;
  registerActive = false;
  index = 0;
  error = false;
  loading = false;
  reCaptchaApiKey = environment.reCaptcha.apiKey;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userType = this.route.snapshot.paramMap.get("type") as
      | "farmer"
      | "plucky";
  }

  loginEmailControl() {
    return this.loginForm.get("emailControl");
  }

  loginPasswordControl() {
    return this.loginForm.get("passwordControl");
  }

  registerEmailControl() {
    return this.registerForm.get("emailControl");
  }

  registerNameControl() {
    return this.registerForm.get("nameControl");
  }

  registerPasswordControl() {
    return this.registerForm.get("passwordControl");
  }

  registerConfirmPasswordControl() {
    return this.registerForm.get("confirmPasswordControl");
  }

  registerPrivacyControl() {
    return this.registerForm.get("privacyControl");
  }

  registerNewsletterControl() {
    return this.registerForm.get("newsletterControl");
  }

  toggleLogin() {
    this.error = false;
    this.registerActive = !this.registerActive;
  }

  handleError() {
    this.error = true;
    this.loading = false;
  }

  async login() {
    this.loading = true;
    console.log("logging in...");
    this.error = false;
    const email = this.loginEmailControl().value;
    const password = this.loginPasswordControl().value;
    try {
      await this.auth.emailLogin(email.trim(), password);
      if (this.userType === "plucky") {
        this.router.navigateByUrl("/employee");
      } else if (this.userType === "farmer") {
        this.router.navigateByUrl("/farmer");
      }
    } catch (error) {
      console.log(error);
      this.handleError();
    }
  }

  async register() {
    this.loading = true;
    console.log("registering...");
    this.error = false;
    const email = this.registerEmailControl().value;
    const name = this.registerNameControl().value;
    const password = this.registerPasswordControl().value;
    const newsletter = this.registerNewsletterControl().value;
    try {
      await this.auth.emailRegister(email, name, password, this.userType);
      if (this.userType === "plucky" && !this.error) {
        this.router.navigateByUrl("/employee");
      } else if (this.userType === "farmer") {
        this.router.navigateByUrl("/farmer");
      }
    } catch (error) {
      console.log(error);
      this.handleError();
    }
  }

  reCaptchaResolved(event) {
    console.log(event);
  }
}
