<div
  [ngClass]="{
    'farm-container': !showApplyButton,
    'farm-container-clickable': showApplyButton
  }"
  (click)="goToDetail()"
>
  <div
    *ngIf="farm.image"
    class="avatar"
    [style.background-image]="'url(' + farm.image + ')'"
  ></div>
  <div class="avatar-placeholder" *ngIf="!farm.image"></div>

  <div class="farm-name">{{ farm.name }}</div>
  <div class="apply-button" *ngIf="showApplyButton">
    JETZT BEWERBEN
  </div>
</div>
