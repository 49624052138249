<mat-card>
  <mat-card-header>
    <mat-card-title>
      Inhaltlich Verantwortlicher
    </mat-card-title>
    <mat-card-subtitle>
      gem. § 55 Abs. 2 RStV
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ul>
      <li>Patrick Dinkelacker</li>
      <li>Strickerstraße 10</li>
      <li>DE-72622 Nürtingen</li>
      <li>info@myplucky.eu</li>
    </ul>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Verbraucherstreitbeilegung
    </mat-card-title>
    <mat-card-subtitle>
      Information nach § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit,
      die Sie unter folgender Adresse erreichen können <a href="https://ec.europa.eu/consumers/od">https://ec.europa.eu/consumers/od</a>.<br>
      Zur Teilnahme an einem Streitbeilegungsverfahren sind wir nicht bereit und nicht verpflichtet.
      Gerne klären wir etwaige Meinungsverschiedenheiten im direkten Austausch mit Ihnen.
      Bei Fragen und Problemen kontaktieren Sie uns daher bitte.
    </p>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Urheber- / Bilder- / Markenrechte
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ul>
      <li>© Unsplash</li>
      <li>© Fonticons, Inc.</li>
      <li>© Google LLC.</li>
    </ul>
  </mat-card-content>
</mat-card>
