import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { Farm } from "src/app/models/farm.model";
import { ApiService } from "src/app/services/api.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-employee-home-modal",
  templateUrl: "./employee-home-modal.component.html",
  styleUrls: ["./employee-home-modal.component.scss"],
})
export class EmployeeHomeModalComponent implements OnInit {
  @Input() farms: Farm[];
  @Input() selectedFarmInput: Farm;
  @Output() hideModal = new EventEmitter();

  selectedFarm: Farm;
  owner: User;

  closeIcon = faTimes;
  backIcon = faArrowLeft;

  constructor(
    public api: ApiService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (this.selectedFarmInput) this.goToDetail(this.selectedFarmInput);
  }

  goToDetail(farm: Farm) {
    this.api.getFarm(farm.id).subscribe((selectedFarm) => {
      this.selectedFarm = selectedFarm;
      if (this.selectedFarm) {
        this.api.getProfile(this.selectedFarm.member[0]).subscribe((owner) => {
          this.owner = owner;
        });
      }
    });
    this.auth.user$.subscribe((user) => {
      if (user.type == "plucky") this.updatePluckyFoundOtherParty(user);
    });
  }

  async updatePluckyFoundOtherParty(user: User) {
    user.foundOtherParty = true;
    await this.api.updateProfile(user).catch((err) => {
      console.log(err);
    });
  }

  goToList() {
    this.selectedFarm = null;
    this.owner = null;
  }

  async apply() {
    await this.api.applyToFarm(this.selectedFarm.id);
    this.auth.user$.subscribe((user) => {
      if (user.type == "plucky") this.updatePluckyEngagedOtherParty(user);
    });
  }

  async updatePluckyEngagedOtherParty(user: User) {
    user.engagedOtherParty = true;
    await this.api.updateProfile(user).catch((err) => {
      console.log(err);
    });
  }

  async deregister() {
    await this.api.unapplyForProject(this.selectedFarm.id);
  }

  applied() {
    if (this.auth.user) {
      const uid = this.auth.user.uid;
      return (
        this.selectedFarm.applicants.includes(uid) ||
        this.selectedFarm.confirmedApplicants.includes(uid)
      );
    } else {
      return false;
    }
  }
}
