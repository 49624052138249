<div>
  <mat-toolbar class="appWidth" color="primary">
    <a
      *ngFor="let option of activeOptions"
      [routerLink]="option.routerLink"
      routerLinkActive="active"
    >
      <fa-icon [icon]="option.icon"></fa-icon>
      <div>{{ option.name }}</div>
    </a>
  </mat-toolbar>
</div>
